<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>
    <v-card class="pa-3">
      <v-container>
        <BaseSelect
          single-line
          label="Tipo Meta"
          item-text="tipo_meta"
          item-value="id_tipo_meta"
          service="campanhaService.tipoMeta"
          v-model="formTipoMetaPagamento.id_tipo_meta"
          :rules="[rules.required]"
          clearable
          :loading="tipoMeta.loading"
          @change="(tipoMeta.selected = $event), handleFilter()"
        >
        </BaseSelect>

        <BaseSelect
          single-line
          label="Tipo Pagamento"
          item-text="tipo_pagamento"
          item-value="id_tipo_pagamento"
          service="campanhaService.tipoPagamento"
          clearable
          v-model="formTipoMetaPagamento.id_tipo_pagamento"
          :rules="[rules.required]"
          :loading="tipoPagamento.loading"
          @change="(tipoPagamento.selected = $event), handleFilter()"
        >
        </BaseSelect>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import rules from "@/mixins/inputRules";
import FilterBottom from "@/components/shared/bottons/FilterBottom";
import BaseSelect from "@/components/shared/BaseSelectService";
import serviceParametros from "@/services/parametros";

export default {
  name: "Filtro",
  mixins: [rules],

  components: {
    BaseSelect,
    FilterBottom
  },
  props: {
    tipoMetaPagamento: {
      type: Object
    },
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    }
  },

  data() {
    return {
      formTipoMetaPagamento: {},
      filters: {},
      tipoMeta: {
        items: [],
        loading: false,
        selected: ""
      },
      tipoPagamento: {
        items: [],
        loading: false,
        selected: ""
      }
    };
  },

  methods: {
    async fechTipoMeta() {
      try {
        this.tipoMeta.loading = true;
        const { data } = await serviceParametros.getTipoMetas({
          per_page: -1,
          ativo: "S"
        });
        this.tipoMeta.items = data.data;
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "error",
          text: "Erro ao buscar tipo de meta"
        });
      } finally {
        this.tipoMeta.loading = false;
      }
    },

    async fechTipoPagamento() {
      try {
        this.tipoPagamento.loading = true;
        const { data } = await serviceParametros.getTipoPagamento({
          per_page: -1,
          ativo: "S"
        });
        this.tipoPagamento.items = data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.tipoPagamento.loading = false;
      }
    },

    handleFilter() {
      this.$emit("selectedFilters", {
        id_tipo_meta: this.tipoMeta.selected || null,
        id_tipo_pagamento: this.tipoPagamento.selected || null
      });
    }
  },

  async mounted() {
    try {
      await this.fechTipoMeta();
      await this.fechTipoPagamento();
    } catch (error) {
      console.error(error);
    }
  }
};
</script>
