<template>
  <div>
    <BaseTable
      :headers="headers"
      :loading="loading"
      :paginate="true"
      :items="items"
      sort-by="id_tipo_meta_pagamento"
      class="table-shadow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Tipos de Metas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="587"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <Filtro
            class="mb-5"
            @selectedFilters="fetchMetaPagamento((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          icon
          v-can-access="588"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="590"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->
        <IconBottom :name="'edit'" v-can-access="588" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="590"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalTipoMetaPagamento
      :tipoMetaPagamento="pagamento"
      :dialog="dialog"
      :labelBtn="labelBtn"
      @close="refresh()"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable";
import Filtro from "./Filtro";
import ModalTipoMetaPagamento from "./ModalTipoMetaPagamento";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import campanha from "@/services/http/campanhaService";

export default {
  name: "Tabela",

  components: {
    BaseTable,
    ModalTipoMetaPagamento,
    Filtro,
    RegisterBottom,
    IconBottom
  },

  data() {
    return {
      filters: {},
      labelBtn: "",
      dialog: false,
      pagamento: {},
      headers: [
        { text: "Tipo Meta", value: "tipo_meta" },
        { text: "Tipo Pagamento", value: "tipo_pagamento" },
        { text: "Descrição", value: "descricao" },
        { text: "Ações", value: "acoes" }
      ],
      items: [],
      loading: false
    };
  },

  methods: {
    editItem(item) {
      this.pagamento = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    async fetchMetaPagamento(filters) {
      this.loading = true;
      const { data } = await campanha()
        .tipoMetaPagamento()
        .show({
          per_page: -1,
          ativo: "S",
          tipoMetaPagamento: "S",
          ...filters
        });

      this.items = data.data;
      this.loading = false;
    },
    refresh() {
      this.dialog = false;
      this.loading = true;
      this.fetchMetaPagamento(this.filters);
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deseja deletar Parametro?",
          "Essa ação não pode ser desfeita"
        );
        campanha()
          .tipoMetaPagamento(item.id_tipo_meta_pagamento)
          .delete(
            {},
            {
              notification: true,
              message: "Meta deletada com sucesso!"
            }
          );
        this.refresh();
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar valor fixo"
        });
      } finally {
        this.fetchMetaPagamento(this.filters);
      }
    }
  },
  async mounted() {
    await this.fetchMetaPagamento();
  }
};
</script>
